@import "bootstrap";
@import "fonts";

$main-container-max-width: 992px;

.cursor-pointer {
  cursor: pointer;
}

.navigation-container,
main {
  width: 100%;
  max-width: $main-container-max-width;

  margin: 0 auto;
}

.ratio-1 {
  aspect-ratio: 1;
}

.avatar {
  height: 3rem;

  .avatar-login-method-icon {
    scale: 1.5;
  }
}

@include media-breakpoint-down(lg) {
  .mode-switch {
    text-align: center;
    padding-top: 0.5rem;
    margin-left: auto;
  }

  .navigation-container {
    .navbar-brand {
      margin: 0 auto !important;
    }

  }

}